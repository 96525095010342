import React from 'react'
import Layout from '../components/layout'
import PageSelector from '../components/page'

export default ({ pageContext: { description, label, posts, primaryNav,
  secondaryNav, title, template } }) => 
  <Layout description={description} label={label} title={title} 
    primaryNav={primaryNav} secondaryNav={secondaryNav}>
    <PageSelector label={label} posts={posts} template={template} title={title}/>
  </Layout>

