import React from 'react'
import RMD from 'react-markdown'

const PostsList = ({ className, items }) => items.map((item, idx) => 
  <RMD className={className} key={idx} source={item} />
)
  
export default ({ className, items }) => {
  if (items) {
    if (Array.isArray(items)) {
      return <PostsList className={className} items={items} />
    } else {
      return <RMD className={className} source={items} />
    }
  } else {
    return <div className={className} />
  }
}
