import React from 'react'
import SEO from './seo'
import Navigation from './navigation'
import Footer from './footer'

export default ({ 
  children, path, description, label, title, primaryNav, secondaryNav 
}) => {

  return( 
  <div style={{ position: 'relative' }}>
    <SEO label={label} title={title} description={description} />
    <Navigation primary={primaryNav} secondary={secondaryNav} />
    {children}
    <Footer />
  </div>)
}
