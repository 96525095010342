import React from 'react'
import footerStyle from './footer.module.css'

const { footerText, wrapper } = footerStyle
const year = new Date().getFullYear()

export default () => 
  <footer>
    <div className={wrapper}>
      <p className={footerText}>Fejér Noémi honlapja 2013-{year}</p>
      <p className={footerText}>design: Szonja Kiss</p>
    </div>
  </footer>
