import React from 'react'
import WithContentVariant from './with-content-variant'
import SectionHeader from './section-header'
import Posts from './posts'
import adultStyle from './adult.module.css'

const { 
  sectionBg, contentWrapper, elementWrapper, mdWrapper, sectionWrapper 
} = adultStyle

const AdultComponent = ({ posts: { intro, body }, title, variant }) => 
  <div className={sectionBg}>
    <SectionHeader title={title} />
    <div className={sectionWrapper}>
      <div className={contentWrapper}>
        <Posts className={mdWrapper} items={intro} />
      </div>
      <div className={elementWrapper} />
    </div>
    <div className={sectionWrapper}>
      <Posts className={mdWrapper} items={body} /> 
    </div>
  </div>

export default WithContentVariant(AdultComponent)
