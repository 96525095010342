import React from 'react'
import SectionHeader from './section-header'
import Posts from './posts'
import carrierStyle from './carrier.module.css'

const { 
  carrierBg, contentWrapper, elementWrapper, mdWrapper, sectionWrapper 
} = carrierStyle

const CarrierComponent = ({ posts: { intro, body }, title }) => 
  <div className={carrierBg}>
    <SectionHeader title={title} />
    <div className={sectionWrapper}>
      <div className={contentWrapper}>
        <Posts className={mdWrapper} items={intro} />
      </div>
      <div className={elementWrapper} />
    </div>
    <div className={sectionWrapper}>
      <Posts className={mdWrapper} items={body} /> 
    </div>
  </div>

export default CarrierComponent
