import React from 'react'
import { Link } from 'gatsby'
import navCss from './navigation.module.css'

const NavList = ({ items }) => items.map((item, idx) => 
  <li key={idx}>
    <Link to={item.url} activeStyle={{ color: 'white' }}>
      {item.title}
    </Link>
  </li>
)

export default ({ primary, secondary }) => { 
  if (secondary) {
    return (
      <nav style={navCss}>
        <ul className='primary-nav'>
          <NavList items={primary} />
        </ul>
        <ul className={navCss.secondaryNav}>
          <NavList items={secondary} />
        </ul>
      </nav>
    )
  }
  return (
    <nav style={navCss}>
      <ul className='primary-nav'>
        <NavList items={primary} />
      </ul>
    </nav>
  )
}
