import React from 'react'
import Adult from './adult'
import Carrier from './carrier'
import Contact from './contact'
import Child from './child'
import Company from './company'
import Gallery from './gallery'
import Home from './home'

const components = { Adult, Carrier, Child, Company, Contact, Gallery, Home }

const PageComponent = ({ posts, template, title }) => {
  const SelectPage = components[template]

  return <SelectPage posts={posts} title={title}/>
}

export default PageComponent
