import React from 'react'

const getDisplayName = Component => {
  return Component.displayName || Component.name || 'Unknown'
}

const withContentVariant = Template => {
  const WithContentVariant = props => {
    const { posts: { intro, body } } = props
    let variant = 'empty'
    if (Array.isArray(intro) && intro.lenght > 0) {
      if (Array.isArray(body) && body.length > 0) {
        variant = 'full'
      } else {
        variant = 'intro'
      } 
    } else {
      if (Array.isArray(body) && body.length > 0) {
        variant = 'body'
      }
    }

    return <Template variant={variant} {...props} />

  }
  
  WithContentVariant.displayName = `WithContentVariant(${getDisplayName})`

  return WithContentVariant
}

export default withContentVariant
