/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

const SEO = ({ description, label, lang, path, title }) => {
  const metaTitle = `${title} ${label}`
  const meta = []
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `hu`,
  path: `/`,
  description: ``,
  title: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  path: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SEO
