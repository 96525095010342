import React from 'react'
import SectionHeader from './section-header'
import contactStyle from './contact.module.css'

const { 
  sectionBg, contentWrapper, sectionWrapper 
} = contactStyle

const ContactComponent = ({ posts: { intro, body }, title }) => 
  <div className={sectionBg}>
    <SectionHeader title={title} />
    <div className={sectionWrapper}>
      <div className={contentWrapper} />
    </div>
  </div>

export default ContactComponent
