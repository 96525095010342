import React from 'react'
import SectionHeader from './section-header'
import Posts from './posts'
import childStyle from './child.module.css'

const { 
  sectionBg, contentWrapper, elementWrapper, flags, mdWrapper, sectionWrapper 
} = childStyle

const ChildComponent = ({ posts: { intro, body }, title }) => 
  <div className={sectionBg}>
    <SectionHeader title={title} />
    <div className={sectionWrapper}>
      <div className={flags} />
      <div className={elementWrapper} />
      <div className={contentWrapper}>
        <Posts className={mdWrapper} items={intro} />
      </div>
    </div>
    <div className={sectionWrapper}>
      <Posts className={mdWrapper} items={body} /> 
    </div>
  </div>

export default ChildComponent
