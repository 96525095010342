import React from 'react'
import SectionHeader from './section-header'
import Posts from './posts'
import homeStyle from './home.module.css'


const { 
  cvBg, cvContent, cvWrapper, homeBg, homeContent, homeWrapper 
} = homeStyle

const HomeComponent = ({ posts: { intro, body }, title }) => 
  <div>
    <div className={homeBg}>
      <SectionHeader title={title} />
      <div className={homeWrapper}>
        <Posts className={homeContent} items={intro} />
      </div>
    </div> 
    <div className={cvBg}>
      <SectionHeader title='Önéletrajz' />
      <div className={cvWrapper}>
        <Posts className={cvContent} items={body} />
      </div>
    </div> 
  </div>
export default HomeComponent
